import { types, getParent, flow, applySnapshot } from 'mobx-state-tree';
import { OUTBOUND_LIST_TYPES_MAP, OUTBOUND_LIST_TYPE_TODAY, OUTBOUND_LIST_TYPE_COMPLETED, OUTBOUND_LIST_TYPE_CANCELED } from './constants';
import { TypeCountItem, OutboundRow } from './models';
import { resolvePaginationStore } from '../PaginationStore';
import { FilterStore } from './FilterStore';
import { serialize } from 'object-to-formdata';


const OutboundsStore = types
  .model('OutboundsStore', {
    type: types.optional(types.enumeration(Object.keys(OUTBOUND_LIST_TYPES_MAP)), OUTBOUND_LIST_TYPE_TODAY),
    typeCounts: types.frozen(Object.keys(OUTBOUND_LIST_TYPES_MAP).map(t => TypeCountItem.create({ value: t, text: OUTBOUND_LIST_TYPES_MAP[t] }))),
    collection: types.array(OutboundRow),
    pagination: types.optional(
      resolvePaginationStore({
        onLoadMore: (self) => getParent(self).loadMore(),
        onChangePerPage: (self) => getParent(self).loadCollection(),
      }),
      {
        perPage: 20
      },
    ),
    filter: types.optional(FilterStore, {}),
    sortByName: 'id',
    sortByValue: 'desc',
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get isTypeCompleted() {
      return self.type == OUTBOUND_LIST_TYPE_COMPLETED;
    },
    get isTypeCanceled() {
      return self.type == OUTBOUND_LIST_TYPE_CANCELED;
    },
  }))
  .actions(self => ({
    replaceCollection(data) {
      for (let row of self.collection) {
        if (row.id == data.id) {
          applySnapshot(row, data);
        }
      }
    },
    setType(type) {
      self.type = type;
    },
    loadCounts: flow(function* () {
      try {
        const { data } = yield self.root.api.outbound.counts()
        self.typeCounts.forEach(item => {
          item.setCount(data.data[item.value]);
        })
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadCollection: flow(function* ({ page = 1, query = {} } = {}) {
      try {
        const { data } = yield self.root.api.outbound.index({
          ...query,
          type: self.type,
          page: page,
          per_page: self.pagination.perPage,
          sort_by: self.sortByName,
          sort_value: self.sortByValue,
          ...self.filter.toValues(),
        });
        self.collection = data.data
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadMore: flow(function* () {
      try {
        const { data } = yield self.root.api.outbound.index({
          type: self.type,
          page: self.pagination.page,
          per_page: self.pagination.perPage,
          sort_by: self.sortByName,
          sort_value: self.sortByValue,
          ...self.filter.toValues(),
        });
        self.collection.push(...data.data);
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setTypeAndLoad(type) {
      self.pagination.reset();
      self.setType(type);
      self.loadCollection();
    },
    viewPDF: flow(function* (id, withSummary, bolIds, hideCarrerInBOL) {
      try {
        yield self.root.api.outbound.viewPDF(id, withSummary, bolIds, hideCarrerInBOL);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    export: flow(function* (id) {
      try {
        yield self.root.api.outbound.exportExcel(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    printOBExcel: flow(function* (id, bolIds, filename) {
      try {
        yield self.root.api.outbound.printOBExcel(id, bolIds, filename);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    printEFBolsExcel: flow(function* (id, withSummary, bolIds, hideCarrerInBOL, filename) {
      try {
        yield self.root.api.outbound.printEFBolsExcel(id, withSummary, bolIds, hideCarrerInBOL, filename);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    printPDF: flow(function* (id, withSummary, bolIds) {
      try {
        yield self.root.api.outbound.printPDF(id, withSummary, bolIds);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    shipout: flow(function* (outbound, data) {
      if (!window.confirm('Are you sure to Confirm Ship Out')) {
        return;
      }

      const formData = serialize({ ...data, documents: [...outbound.shipoutDocuments.toOriginalFiles()] }, { indices: true, nullsAsUndefineds: true })

      let response = false;
      try {
        response = yield self.root.api.outbound.shipout(outbound.id, formData);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      return response;
    }),
    unshipout: flow(function* (outbound, data) {
      if (!window.confirm('Are you sure to Cancel Ship Out')) {
        return;
      }

      let response = false;
      try {
        response = yield self.root.api.outbound.unshipout(outbound.id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      return response;
    }),
    updateRemark: flow(function* (id, remark) {
      try {
        const response = yield self.root.api.outbound.updateRemark(id, remark);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    sortBy(name, value) {
      self.sortByName = name;
      self.sortByValue = value;
      self.loadCollection();
    },
  }));

export default OutboundsStore;
