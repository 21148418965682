import React, { useEffect, useState, useRef } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import { isFunction } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { EditIcon } from '../ui/Icon';
import { observer, inject } from 'mobx-react';

const Editable = inject('root')(observer(({
  color = 'primary',
  size = "small",
  render,
  value,
  children,
  onShowEdit,
  onHideEdit,
  onHideEditChange,
  buttonClassName,
  root,
  renderValue = (v) => v,
  resigster,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const { inputManager } = root;

  const hide = () => {
    setEditing(false);
    onHideEdit && onHideEdit(currentValue);

    if (currentValue !== value) {
      onHideEditChange && onHideEditChange(currentValue);
    }
  }

  useEffect(() => {
    if (resigster) {
      inputManager.register(resigster, () => show())
    }
  })

  const show = () => {
    setEditing(true);
    if (resigster) {
      inputManager.setCurrentInput(resigster);
    }
    setCurrentValue(value);
    onShowEdit && onShowEdit();
  }

  const onChange = v => setCurrentValue(v);
  const handlePressKey = (e) => {
    if (e.keyCode === 40 && resigster) {
      inputManager.next()
      hide()
    }
  }

  return (
    <div onKeyDown={(e) => handlePressKey(e)}   {...props} style={{ width: '100%', height: '100%' }}>
      {isEditing ? (
        <OutsideClickHandler onOutsideClick={hide}>
          {children({
            value: currentValue,
            onChange,
          })}
        </OutsideClickHandler>
      ) : (
        <div
          onClick={show}
          style={{ width: '100%', height: '100%' }}
        >
          {render && isFunction(render)
            ? render()
            : <Text buttonClassName={buttonClassName} renderValue={renderValue} value={render !== undefined ? render : value} color={color} size={size} />
          }
        </div>
      )}
    </div>
  );

}));

const Text = ({ value, size = "small", color = "primary", buttonClassName, renderValue }) => value !== '' && value !== undefined && value !== null ? <Button className={buttonClassName} color={color} size={size}>{renderValue(value)}</Button> : <IconButton className={buttonClassName} size={size} color={color}><EditIcon fontSize="inherit" /></IconButton>

export default Editable;
