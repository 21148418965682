import { types, getParent, getRoot, applySnapshot, flow } from 'mobx-state-tree';
import DocumentsStore from '../document/DocumentsStore';
import { flatten, uniqBy } from 'lodash';

export const TypeCountItem = types
  .model({
    value: types.string,
    text: types.string,
    count: types.maybeNull(types.number),
  })
  .actions(self => ({
    isActive(type) {
      return self.value === type;
    },
    setCount(count) {
      self.count = count;
    },
  }));

export const OutboundRowBolItem = types
  .model({
    ib_shipment_number: types.maybeNull(types.string),
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
  })

export const OutboundRowBolLoad = types
  .model({
    id: types.number,
    uid: types.string,
  })

export const OutboundRowBol = types
  .model({
    id: types.number,
    uid: types.string,
    ib_shipment_numbers: types.array(types.string),
    delivery_code: types.maybeNull(types.string),
    delivery_appointment_time: types.maybeNull(types.string),
    delivery_address: types.maybeNull(types.string),
    qty: types.maybeNull(types.union(types.number, types.string)),
    internal_remark: types.maybeNull(types.string),
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    whs_markings: types.array(types.union(types.string, types.number)),
    loads: types.array(OutboundRowBolLoad),
    items: types.array(OutboundRowBolItem),
    is_ready_to_ship: types.maybeNull(types.boolean),
  })

export const OutboundRow = types
  .model({
    id: types.number,
    uid: types.string,
    status_name: types.maybeNull(types.string),
    carrier_code: types.maybeNull(types.string),
    carrier_name: types.maybeNull(types.string),
    truck_type_name: types.maybeNull(types.string),
    scheduled_pickup_time: types.maybeNull(types.string),
    shipout_at: types.maybeNull(types.string),
    type_name: types.maybeNull(types.string),
    booked_qty: types.maybeNull(types.union(types.number, types.string)),
    total_qty: types.maybeNull(types.union(types.number, types.string)),
    total_inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_weight_lb: types.maybeNull(types.union(types.number, types.string)),
    bols: types.array(OutboundRowBol),
    shipoutDocuments: types.optional(DocumentsStore, { target: 'outbound_shipout' }),
    adminDocuments: types.optional(DocumentsStore, { target: 'outbound_admin' }),
    remark: types.maybeNull(types.string),
    selectedBols: types.array(OutboundRowBol),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get outbounds() {
      return getParent(self, 2);
    },
    get selectedLoads() {
      return uniqBy(flatten(self.selectedBols.map(s => s.loads ? s.loads : s).slice()), 'id');
    },
  }))
  .volatile(self => ({
    loading: false,
    hide_carrier_in_bol: false,
  }))
  .actions(self => ({
    reload: flow(function* () {
      try {
        const response = yield self.root.api.outbound.show(self.id);
        applySnapshot(self, response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setSelected(selectedBols) {
      self.selectedBols = selectedBols;
    },
    setLoading(indicator) {
      self.loading = !!indicator;
    },
    viewPDF(withSummary = true, bolIds = []) {
      self.setLoading(true);

      return new Promise((resolve) => {
        getParent(self, 2).viewPDF(self.id, withSummary, bolIds, self.hide_carrier_in_bol).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    printOBExcel(bolIds) {
      self.setLoading(true);
      return new Promise((resolve) => {
        getParent(self, 2).printOBExcel(self.id, bolIds, self.uid).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    printEFBolsExcel(withSummary = true, bolIds) {
      self.setLoading(true);
      return new Promise((resolve) => {
        getParent(self, 2).printEFBolsExcel(self.id, withSummary, bolIds, self.hide_carrier_in_bol, self.uid).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    export(id) {
      self.setLoading(true);

      return new Promise((resolve) => {
        getParent(self, 2).export(self.id).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    printPDF(withSummary = true, bolIds = []) {
      self.setLoading(true);

      return new Promise((resolve) => {
        getParent(self, 2).printPDF(self.id, withSummary, bolIds).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    shipout(data) {
      self.setLoading(true);

      return new Promise((resolve) => {
        getParent(self, 2).shipout(self, data).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    unshipout() {
      self.setLoading(true);

      return new Promise((resolve) => {
        getParent(self, 2).unshipout(self).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    updateRemark(remark) {
      self.setLoading(true);

      return new Promise((resolve) => {
        getParent(self, 2).updateRemark(self.id, remark).then(response => {
          self.setLoading(false);
          resolve(response);
        });
      });
    },
    setHideCarrerInBOL(flag) {
      self.hide_carrier_in_bol = flag;
    },
  }))


